
/* You can add global styles to this file, and also import other style files */
@import "./assets/styles/tables.scss";
@import "./assets/styles/main.scss";
@import "./assets/styles/common/common.scss";
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.md-drppicker {
    width: 600px !important;
}

