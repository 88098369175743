@media screen and (max-width:1120px) {
    .page-title-main {
        display: none;
    }
}
.scrollbar::-webkit-scrollbar {
  height: 8px;
  /* Height of the scrollbar */
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  /* Customize the scrollbar thumb color */
  border-radius: 10px;
  /* Rounded scrollbar */
}

.scrollbar::-webkit-scrollbar-track {
  background: transparent;
  /* Scrollbar track background */
}
.green-color {
    color: #afcb3a;
}
.import-btn {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
  }
  .import-btn .btn, .q-import-btn {
    padding: .375rem .75rem;
    font-size: 16px;
    background-color: #8836be;
    color: white;
    cursor: pointer;
    border: none;
    &:hover {
      background-color: #6a2b94;
    }
  }

  .import-btn input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .tag-item, .form-horizontal .multiselect-dropdown .dropdown-btn .selected-item {
      background-color: #afcb3a !important;
      color: #fff;
      padding: 3px 10px !important;
      border-radius: 20px !important;
      margin-right: 5px !important;
      margin-bottom: 5px !important;
      font-size: 12px;
      border: none !important;
      display: inline-block !important;
      cursor: pointer !important;
      width: fit-content !important;

    .remove-tag, a {
        background: #fff !important;
        color: #afcb3a !important;
        width: 15px !important;
        height: 15px !important;
        line-height: 12px;
        border-radius: 50%;
        display: inline-block;
        font-weight: bold;
        vertical-align: middle;
        text-align: center;
        font-size: 12px;
        margin-left: 5px;
        margin-top: -3px;
    }

  }
  ::ng-deep{
    .swal2-container{
      z-index: 50000 !important;
    }
  }
  .input-tag  {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    width: 100%;
  }
  .form-horizontal input {
    font-size: 14px;
  }
  .form-horizontal  .multiselect-dropdown .dropdown-btn {
    padding: 5px 10px !important;
    border: 1px solid #ced4da !important;
  }
  .form-horizontal  .multiselect-dropdown .dropdown-btn span {
    font-size: 14px;
    // color: #495057;
  }
  .form-horizontal .multiselect-dropdown .dropdown-btn .selected-item {
    // margin-bottom: 0 !important;
    max-width: fit-content !important;
    margin: 3px 0;
  }
  .multiselect-dropdown .dropdown-btn .selected-item {
    max-width: fit-content !important;
    margin: 3px 0;
  }
  .form-horizontal .multiselect-dropdown .dropdown-list li {
    font-size: 14px;
    padding: 5px 15px !important;
  }
  .form-horizontal .multiselect-dropdown .dropdown-list ul.item2 {
    overflow-y: scroll;
    max-height: 160px;
  }

.form-horizontal .multiselect-dropdown .dropdown-list ul.item2::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
  border-radius: 5px;
}

.form-horizontal .multiselect-dropdown .dropdown-list ul.item2::-webkit-scrollbar-thumb
{
	background-color: #cccccc;
}
.multiselect-item-checkbox input[type=checkbox] + div:before {
  border: 1px solid #afcb3a !important;
}
.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
  background-color: #afcb3a !important;
}
.multiselect-item-checkbox input[type=checkbox] + div:after {
  border-width: 0 0 2px 2px !important;
}
.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  border-width: 5px 5px 0 !important;
}
.multiselect-dropdown .dropdown-list {
  padding: 0 !important;
}
.dropdown-toggle::after {
  display: none;
}