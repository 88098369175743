.custom-table { 
	// width: 750px; 
	width: 100%;
	border-collapse: collapse; 
	margin:0;
	}

/* Zebra striping */
.custom-table tr:nth-of-type(odd) { 
	background: #eee; 
	}

.custom-table th { 
	background: #3498db; 
	color: white; 
	font-weight: bold; 
	}

.custom-table td, th { 
	padding: 10px; 
	border: 1px solid #ccc; 
	text-align: left; 
	font-size: 12px;
	// font-size: 18px;
	}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	table { 
	  	width: 100%; 
	}

	/* Force table to not be like tables anymore */
	.custom-table table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	.custom-table thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	.custom-table tr { border: 1px solid #ccc; }
	
	.custom-table td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 

		position: relative;
		padding-left: 50%; 
	}

	.custom-table td:before { 
		position: absolute;
		// top: 5px;
		line-height: 1;
		left: 6px;
		width: 45%;
		/* padding-bottom: 24px; */
		white-space: pre-wrap;
		content: attr(data-column);
		color: #000;
		font-weight: 400;
	}

}